<template>
	<div class="econ">
		<breadcrumb icon="el-icon-s-grid"></breadcrumb>
		<div class="tabbox">
			<el-tabs v-model="activeMode" class="tabsbox" @tab-click="handleClick">
				<el-tab-pane label="文件资料" name="2"></el-tab-pane>
				<!-- <el-tab-pane label="图片资料" name="1"></el-tab-pane> -->
			</el-tabs>
		</div>
		<div class="actionBox">
			<div class="shangchuan">
				上传日期:
			</div>
			<el-date-picker size="mini" style="width:200px" v-model="range" type="monthrange" value-format="yyyy-MM"
				range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="yueduchange">
			</el-date-picker>
			<el-button style="margin-left:40px;" size="mini" @click="getTableData" type="primary">查询</el-button>
		</div>

		<div class="contable">
			<template>
				<el-table :data="tableData" border style="width: 100%">
					<el-table-column v-if="activeMode === '1'" label="图片" width="100">
						<template slot-scope="scope">
							<img style="width:80px;height:80px;border:solid 1px #ccc; border-radius:5px;"
								:src="scope.row.thumb" />
						</template>
					</el-table-column>
					<el-table-column prop="name" label="资料名称">
					</el-table-column>
					<el-table-column prop="addtime" label="上传时间">
					</el-table-column>
					<el-table-column fixed="right" label="操作" width="100">
						<template slot-scope="scope">
							<download-button size="mini" :params="{
							  goods_id: 8,
							  condition: { promote_id:scope.row.id }
							}" text="下载"></download-button>

						</template>
					</el-table-column>

				</el-table>

			</template>
		</div>
		<el-pagination style="margin-top:20px;" background layout="prev, pager, next" :page-size="size" :total="pagetotal"
			@current-change="changepage">
		</el-pagination>
	</div>

</template>

<script>

import DownloadButton from "@/components/down/DownloadButton.vue"
import { tuijieList } from '@/api/tuijiefile.js'
import Breadcrumb from '@/components/common/Breadcrumb.vue'
export default {
	name: 'VueElementTupaiIndex',

	data() {
		return {
			tableData: [],
			range: [],
			activeMode: '2',
			page: 1,
			size: 20,
			pagetotal: 10
		}
	},
	components: { Breadcrumb,DownloadButton },
	mounted() {
		let d = new Date
		let year1, month1, day1;
		[year1, month1, day1] = [d.getFullYear(), d.getMonth(), d.getDate()]
		const range = [year1 + '-01', year1 + '-' + (parseInt(month1) + 1)] //当年一月 ，当年当月
		this.range = range
		this.getTableData()

	},
	watch: {
		activeMode: {
			handler(v) {
				this.getTableData()
			}
		}
	},
	methods: {
		changepage(e) {
			this.page = e
			this.getTableData()
		},
		yueduchange() {

		},
		handleClick() {

		},
		getTableData() {
			const params = {
				page: this.page,
				size: this.size,
				start: this.range[0],
				end: this.range[1],
				type: this.activeMode
			}
			console.log('params', params)

			tuijieList(params).then(res => {
				this.tableData = res.data.list
				this.pagetotal = res.data.total


			})

		}

	},
};
</script>

<style lang="scss" scoped>
.actionBox {
	width: 96%;
	margin-left: 20px;
	height: 64px;
	background: #F0F3F8;
	border-radius: 4px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-left: 10px;

	.shangchuan {
		width: 80px;
		height: 22px;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #000000;
		line-height: 22px;
		margin-right: 20px;
	}
}

.econ {
	width: 98%;

	overflow: hidden;

	.tabbox {
		padding-top: 5px;
		width: 98%;
		margin: 0 auto;

		.tabsbox {
			// width: 60px;
			margin-left: 20px;
		}
	}
}

.contable {
	width: 96%;
	margin-left: 20px;
	margin-top: 20px;
}

.el-dialog__body{
	justify-content: center;
}
</style>