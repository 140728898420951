import request from '@/utils/request'



//推介资料列表
export function  tuijieList(params) {
  return request({
    url: '/frontend/bigdata/landdata/promote',
    method: 'post',
    params
  })
}
